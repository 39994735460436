// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

function disableScroll() {
  // window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  // window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  // window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  // window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    document.documentElement.style.overflow = 'hidden';
}

function enableScroll() {
    // window.removeEventListener('DOMMouseScroll', preventDefault, false);
    // window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    // window.removeEventListener('touchmove', preventDefault, wheelOpt);
    // window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    document.documentElement.style = '';
  }

// Header
let mobileMenuMq = window.matchMedia('(max-width:1096px)');
const headerSearchToggles = document.querySelectorAll(".menu-search-toggle");
const menuItemsWithChildren = document.querySelectorAll(".menu-item-has-children");

const mobileMenuToggle = document.querySelector('#mobile-menu-toggle');
const mobileMenuToggleText = mobileMenuToggle.querySelector(".text");
const mobileMenuToggleToggleIcon = mobileMenuToggle.querySelector(".toggle");
const mobileMenuToggleCloseIcon = mobileMenuToggle.querySelector(".close");

const mobileMenu = document.querySelector("#mobile-menu");
const mobileMenuBackWrapper = document.querySelector("#mobile-menu .nav-wrapper_back");
const mobileMenuBack = document.querySelector(".mobile-menu-back");
const subMenus = document.querySelectorAll("#mobile-menu .sub-menu");

const openMobileMenu = function() {
    mobileMenu.classList.add("open");
    mobileMenuToggleText.innerHTML = "Close";
    mobileMenuToggleToggleIcon.classList.remove("display");
    mobileMenuToggleCloseIcon.classList.add("display");
    subMenus.forEach(menu => {
        menu.classList.remove("open");
    });
    disableScroll();
}

const closeMobileMenu = function() {
    mobileMenu.classList.remove("open");
    mobileMenuToggleText.innerHTML = "Menu";
    mobileMenuToggleToggleIcon.classList.add("display");
    mobileMenuToggleCloseIcon.classList.remove("display");
    subMenus.forEach(menu => {
        menu.classList.remove("open");
    });
    enableScroll();
}

menuItemsWithChildren.forEach(item => {
    const buttons = item.querySelectorAll(".site-header_button");
    const subMenu = item.querySelector(".sub-menu");

    buttons.forEach(button => {
        button.addEventListener("click", function() {
            subMenu.classList.toggle("open");
            if( mobileMenuMq.matches ) {
                mobileMenuBackWrapper.classList.toggle("open");
            }
        });
    })

    subMenu.addEventListener("mouseleave", function() {
        if( !mobileMenuMq.matches ) {
            subMenu.classList.remove("open");
            const parent = subMenu.parentElement;
            const buttons = parent.querySelectorAll(".site-header_button");
            buttons.forEach(button => {
                button.blur();
            })
        }
    })
});

headerSearchToggles.forEach(toggle => {
    toggle.addEventListener("click", function() {
        const headerSearch = document.querySelector("#header-search");
        headerSearch.classList.toggle("open");
    });
});

mobileMenuToggle.addEventListener("click", function() {
    if(mobileMenu.classList.contains("open")) {
        closeMobileMenu();
    } else {
        openMobileMenu();
    }
});

mobileMenuBack.addEventListener("click", function() {
    subMenus.forEach(menu => {
        menu.classList.remove("open");
    });
    mobileMenuBackWrapper.classList.remove("open");
})

const header = document.querySelector("#masthead");
let prevScroll = window.pageYOffset;

const scrollHeaderAnimation = function() {
    const currentScroll = window.pageYOffset;
    const headerHeight = header.offsetHeight;
        
    if(currentScroll <= headerHeight) {
        header.classList.remove("hide");
        header.classList.add("show");
    }
    else if (prevScroll < currentScroll && !mobileMenu.classList.contains("open")) {
        // Hide header on scroll down
        header.classList.add("hide");
        header.classList.remove("show");
    } else {
        // Show header on scroll up
        header.classList.remove("hide");
        header.classList.add("show");
    }

    prevScroll = currentScroll;
}

window.addEventListener("scroll", function() {
    scrollHeaderAnimation();
});

window.addEventListener('resize', function() {
    if(!mobileMenuMq.matches && mobileMenu.classList.contains("open")) {
        closeMobileMenu();
    }
});


// Swiper

import Swiper from './components/swiper';

const testimonialSwipers = document.querySelectorAll(".testimonial-swiper");
const testimonialSwipersNext = document.querySelectorAll(".testimonial-container .swiper-button-next");
const testimonialSwipersPrev = document.querySelectorAll(".testimonial-container .swiper-button-prev");
const testimonialSwipersPagination = document.querySelectorAll(".testimonial-container .swiper-pagination");

const postSwipers = document.querySelectorAll(".post-swiper");
const postSwipersNext = document.querySelectorAll(".post-container .swiper-button-next");
const postSwipersPrev = document.querySelectorAll(".post-container .swiper-button-prev");

for( let i = 0; i < postSwipers.length; i++ ) {
    
    postSwipers[i].classList.add('post-swiper-' + i);

    const nextButton = postSwipersNext[i];
    const prevButton = postSwipersPrev[i];

    const swiper = new Swiper('.post-swiper-' + i, {
            direction: 'horizontal', 
            spaceBetween: 32,
            loop: true,
            slidesPerView: 3,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            },
    });
  
}

for( let i = 0; i < testimonialSwipers.length; i++ ) {
    
    testimonialSwipers[i].classList.add('testimonial-swiper-' + i);

    const nextButton = testimonialSwipersNext[i];
    const prevButton = testimonialSwipersPrev[i];
    const pagination = testimonialSwipersPagination[i];

    const swiper = new Swiper('.testimonial-swiper-' + i, {
            direction: 'horizontal', 
            spaceBetween: 0,
            loop: true,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            },
            pagination: {
                el: pagination,
            }
    });
  
}

// Lightbox Video

const lightboxVideoBtns = document.querySelectorAll(".button-lightbox-video");
const lightBoxVideos = document.querySelectorAll(".lightbox-video");

lightboxVideoBtns.forEach(button => {
    const buttonId = button.dataset.lightbox;

    button.addEventListener("click", function() {
        lightBoxVideos.forEach(lightbox => {
            const lightboxId = lightbox.dataset.lightbox;
            if( buttonId == lightboxId ) {
                lightbox.classList.add("open");
            }
        })
    })
});

lightBoxVideos.forEach(lightbox => {
    const closeBtn = lightbox.querySelector(".lightbox-video_close");
    closeBtn.addEventListener("click", function() {
        lightbox.classList.remove("open");
    })
});

// Accordion

const accordionItems = document.querySelectorAll(".accordion-item_inner");

accordionItems.forEach(item => {
    const toggleBtns = item.querySelectorAll(".accordion-button-toggle");
    toggleBtns.forEach(btn => {
        btn.addEventListener("click", function() {
            item.classList.toggle('close');
            item.classList.toggle('open');
        });
    })
});

// Interactive Map

const map = document.querySelector('#map');

if( map ) {
    const mapWrapper = document.querySelector('.map-wrapper');
    const mapInfo = document.querySelector('#map-info');
    const mapStates = map.querySelectorAll("path");
    const infoStates = mapInfo.querySelectorAll(".state-info");

    infoStates.forEach(stateInfo => {
        const stateInfoId = stateInfo.dataset.id;
        mapStates.forEach(state => {
            const stateId = state.dataset.id;
            if(stateId === stateInfoId) {
                state.classList.add("active");
            }
            state.addEventListener("click", function() {
                stateInfo.classList.remove("active");
                if(stateId === stateInfoId) {
                    stateInfo.classList.add("active");
                    let 
                        statePosition = state.getBoundingClientRect(),
                        stateX = statePosition.right,
                        stateY = statePosition.top;
                    console.log(stateX);
                    console.log(stateY);
                    mapInfo.classList.add("active");
                    mapInfo.style.top = (stateY + 30) + 'px';
                    mapInfo.style.left = (stateX - 30) + 'px';
                }
            });
        });
    });
    mapWrapper.addEventListener('mouseleave', function (e) {
        mapInfo.classList.remove("active");
    });
}